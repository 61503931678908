export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const CANDLE_BUCKETS_DEFAULT = [
  { label: "1 min", value: "M1", valueChart: 1 },
];

export const ENVIRONMENT = [
  {
    label: "REAL",
    value: "REAL",
  },
  {
    label: "DEMO",
    value: "TEST",
  },
];


export const symbols = [
  {
    symbol: "BTC/USDT",
    name: "BTCUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BTCUSDT.png",
  },
  {
    symbol: "EUR/USD",
    name: "EURUSD",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/EURUSD.png",
  },
  {
    symbol: "ETH/USDT",
    name: "ETHUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ETHUSDT.png",
  },
  {
    symbol: "IDX/USDT",
    name: "IDXUSDT",
    type: "crypto",
    image: "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/IDX.png",
  },
  {
    symbol: "GBP/USD",
    name: "GBPUSD",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/GBPUSD.png",
  },
  {
    symbol: "USD/CHF",
    name: "USDCHF",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDCHF.png",
  },
  {
    symbol: "USD/JPY",
    name: "USDJPY",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDJPY.png",
  },
  {
    symbol: "BNB/USDT",
    name: "BNBUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BNBUSDT.png",
  },
  {
    symbol: "SOL/USDT",
    name: "SOLUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/SOLUSDT.png",
  },
  {
    symbol: "XRP/USDT",
    name: "XRPUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/XRPUSDT.png",
  },
  {
    symbol: "ADA/USDT",
    name: "ADAUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ADAUSDT.png",
  },
  {
    symbol: "IDX/USDT",
    name: "IDXUSDT",
    type: "crypto",
    image: "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/IDX.png",
  },
  {
    description: "IDX/USDT",
    exchange: "Binance",
    full_name: "Binance:IDX/USDT",
    symbol: "IDX/USDT",
    ticker: "IDXUSDT",
    name: "IDXUSDT",
    type: "crypto",
    image: "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/IDX.png",
  },
];