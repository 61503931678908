import type { FC } from "react";
import { SxProps, Theme, styled } from "@mui/material/styles";
import logoEbinex from "src/assets/images/newLogo.png";

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = styled("img")();

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot src={logoEbinex} height="52" {...props} />
);

export default Logo;
