import { useContext, useEffect, useMemo } from "react";
import LayoutContext from "src/contexts/LayoutContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import { apiGet } from "src/services/apiService";
import useSWR from "swr";
import useFavoritesSymbols from "./use-favorites-symbols";

export default function useAvailableSymbols() {
  const { data, isValidating, mutate } = useSWR<any>(
    "/orders/availableSymbols",
    apiGet,
    {
      dedupingInterval: 10000,
    }
  );

  const { favoritesSymbols } = useFavoritesSymbols();

  const { setModalOutsideMarket } = useContext(LayoutContext);
  const { selectedSymbol } = useContext(TradingViewContext);

  const symbols = useMemo(() => {
    if (data) {
      return data.map((item) => ({
        ...item,
        favorite:
          Array.isArray(favoritesSymbols) &&
          favoritesSymbols.some(
            (fav) => fav.symbol === item.symbol && fav.favorite
          ),
      }));
    }
    return [];
  }, [data, favoritesSymbols]);

  useEffect(() => {
    const activeSymbol = symbols?.find(
      (item) => item.symbol === selectedSymbol
    );

    if (activeSymbol && activeSymbol.marketStatus !== "OPEN") {
      setModalOutsideMarket(
        true,
        `O gráfico do índice ${activeSymbol.symbol} está fechado no momento.`
      );
    }
  }, [symbols, selectedSymbol, setModalOutsideMarket]);

  return {
    symbols,
    loading: !data,
    isValidating,
    mutate,
  };
}
