import { Box, Stack, useMediaQuery } from "@mui/material";
import type { FC } from "react";
import { useContext, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useSubscription } from "react-stomp-hooks";

import { useTheme } from "@mui/styles";
import { TVChartContainer } from "src/components/TVChartContainer/test";
import LayoutContext from "src/contexts/LayoutContext";
import useApiData from "src/hooks/useApiData";
import { useLocalStorage } from "usehooks-ts";
import OrderHistory from "../../components/trade/OrderHistory";
import gtm from "../../lib/gtm";

import { candleUpgradeOnStream } from "src/components/TVChartContainer/streaming";
import TradeOrder from "src/components/trade/TradeOrder";
import AccountContext from "src/contexts/AccountContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import useOrderBook from "src/hooks/useOrderBook";

const OverviewTestTradingview: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { setModalAlertWellcomeModal } = useContext(LayoutContext);
  const {
    tvWidget,
    selectedSymbol,
    eventsPending,
    removeEventPending,
    addEventPending,
  } = useContext(TradingViewContext);

  const {
    selectedCandle,
    handleBalanceEvent,
    handleUserOrdersEvent,
    handleCandleClose,
    handleWinLose,
    handleRefundedOrder,
    handleTicker,
    handleServerTime,
  } = useApiData();
  const { handleCurrentBook, clearBook } = useOrderBook();
  const { activeAccount, accounts, setActiveAccount, setAccounts } =
    useContext(AccountContext);

  const [environment] = useLocalStorage("environment", "TEST");

  useEffect(() => {
    // setTimeout(() => {
    //   LogRocket.init("3fv7kl/ebinex", {
    //     mergeIframes: true,
    //     childDomains: ["*"],
    //   });

    //   LogRocket.identify(user.id, {
    //     name: user.name,
    //     email: user.email,
    //   });
    // }, 2000);
    gtm.push({ event: "page_view" });

    const firstAccess = window.localStorage.getItem("firstAccess");

    if (!firstAccess) {
      setModalAlertWellcomeModal(true);
      window.localStorage.setItem("firstAccess", "concluded");
    }
  }, []);

  useSubscription(`/user/topic/${environment}`, async (message) => {
    const { data } = JSON.parse(message.body);
    if (!data) return;
    if (!tvWidget) {
      addEventPending(data);
      return false;
    }
    if (tvWidget) {
      handleDispetchPrivateEvents(data);
    }
  });

  useSubscription(
    `/topic/book:${environment}:${selectedSymbol}:${selectedCandle}`,
    async (message) => {
      const { data } = JSON.parse(message.body);
      if (!data) return;

      switch (data.event) {
        case "book":
          handleCurrentBook(data);
          break;
        case "ticker":
          handleTicker(data);
          break;
        default:
          break;
      }
    }
  );

  useEffect(() => {
    if (tvWidget && tvWidget.activeChart()) {
      for (const data of eventsPending) {
        // handleDispetchPrivateEvents(data);
      }
      removeEventPending();
    }
  }, [tvWidget]);

  const handleDispetchPublicEvents = (data: any) => {
    if (data.event === "trade") {
      candleUpgradeOnStream(data.payload);
    }
  };

  const handleDispetchPrivateEvents = (data: any) => {
    if (data.event === "trade") {
      candleUpgradeOnStream(data.payload);
    } else {
      switch (data.event) {
        case "user_orders":
          console.log("3 - user_orders", data);
          handleUserOrdersEvent(data);
          break;
        case "lose_order":
        case "win_order":
          handleWinLose(data);
          break;
        case "user_balance":
          if (activeAccount.environment === environment) {
            const accountsUpdated = accounts.map((account) => {
              if (account.id === activeAccount.id) {
                return {
                  ...account,
                  defaultCoinBalance: data.payload.usdt,
                };
              }
              return account;
            });
            const newActiveAccount = accountsUpdated.find(
              (c) => c.id === activeAccount.id
            );
            setActiveAccount(newActiveAccount);
            setAccounts(accountsUpdated);
            handleBalanceEvent(data);
          }
          break;
        case "candle_close":
          handleCandleClose(data);
          clearBook(data);
          break;
        case "server_time":
          handleServerTime(data);
          break;
        default:
          break;
      }
    }
  };

  const key = useMemo(() => {
    return `${activeAccount?.id}-${selectedSymbol}`;
  }, [activeAccount, selectedSymbol]);

  return (
    <Box sx={{ display: "flex", mb: isMobile ? 8 : undefined }} key={key}>
      <Helmet>
        <title>Traderoom | Ebinex</title>
      </Helmet>
      <Stack
        spacing={2}
        direction={isMobile ? "column" : "row"}
        sx={{
          flexGrow: 1,
          pt: 6,
          pr: isMobile ? 0 : 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          <TVChartContainer />
          {!isMobile && <OrderHistory page="overview" isSimplified />}
        </Box>

        <TradeOrder />
      </Stack>
      {/* <Box
        sx={{
          minHeight: "100%",
          flexGrow: 1,
          pt: isMobile ? 4.5 : 7,
          px: 1,
        }}
      >
        <Grid container spacing={isMobile ? 0 : 2}>
          <Grid item xs={12} sm={9} md={9} lg={10}>
            <Grid container spacing={0}>
              <Grid
                sx={{
                  position: "relative",
                  mt: 1,
                }}
                item
                xs
                alignItems="stretch"
              >
                <TVChartContainer />
              </Grid>
            </Grid>

            {!isMobile && <OrderHistory page="overview" isSimplified />}
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2} sx={{
               
                width: "200px !important"
              }}>
            <Box
              sx={{
                height: "100%",
                width: 200
              }}
            >
              <PlaceOrder />
              {isMobile && <SubHeaderDense />}
              <OrderBook />
              {isMobile && <OrderHistory page="overview" isSimplified />}
            </Box>
          </Grid>
        </Grid>
      </Box> */}
    </Box>
  );
};

export default OverviewTestTradingview;
