import {
  Box,
  Chip,
  CircularProgress,
  Paper,
  // IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { format } from "date-fns";
import { orderBy } from "lodash";
import numeral from "numeral";
import { useCallback, useContext, useEffect, useState } from "react";
import Scrollbar from "../../Scrollbar";
// import TrashIcon from '../../../icons/Trash';
import { useTranslation } from "react-i18next";
import DetailItemHistory from "src/components/modals/DetailItemHistory";
import LayoutContext from "src/contexts/LayoutContext";
import useAvailableSymbols from "src/swr/use-available-symbols";
import useParameters from "src/swr/use-parameters";
import labelsColors from "src/theme/labelsColors";
import useApiData from "../../../hooks/useApiData";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { apiGet } from "../../../services/apiService";
import { Order, OrderDirectionEnum } from "../../../types/order";
import translate from "../../../utils/translatorUtil";

const labelColors = {
  complete: "success",
  pending: "warning",
  rejected: "error",
  // orders
  OPEN: "info",
  PENDING: "warning",
  REFUNDED: "error",
  WIN: "success",
  LOSE: "error",
};

let Columns = [
  "order_id",
  "active",
  "candle",
  "time",
  "candle_time",
  "prevision",
  "value",
  "fees",
  "reversed",
  "executed",
  "status",
  "result",
];

interface TableOrderHistoryProps {
  selectedTab: number;
  statuses: string[];
  page?: string;
  isSimplified: boolean;
}

export default function TableOrderHistory(props: TableOrderHistoryProps) {
  const { t } = useTranslation("dashboard");
  const isMountedRef = useIsMountedRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { parameters } = useParameters();
  const { symbols } = useAvailableSymbols();
  const { layout } = useContext(LayoutContext);
  const { userOrders, updateUserOrders } = useApiData();
  // eslint-disable-next-line react/prop-types
  const { selectedTab, statuses, page, isSimplified } = props;

  const [itemDetail, setItemDetail] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  // removing columns for simplified version
  if (isSimplified) {
    Columns = Columns.filter(
      (it) =>
        it !== "order_id" && it !== "time" && it !== "fees" && it !== "action"
    );
  }

  const columns = Columns.filter((c) =>
    isMobile
      ? ["active", "candle", "candle_time", "prevision", "result"].includes(c)
      : c
  );

  const fetchOrders = async () => {
    if (!parameters) {
      return;
    }
    setLoading(true);
    try {
      const candleTimeFrames = JSON.parse(parameters.CANDLE_TIME_FRAMES.value);
      const timeFrames = candleTimeFrames.map((item) => item.value).join(",");
      let symbolsList = symbols.map((item) => item.symbol).join(",");

      const response = await apiGet<Order[]>(
        statuses.length === 0
          ? `/orders?candleTimeFrames=${timeFrames}&symbols=${symbolsList}&page=0&size=10`
          : `/orders?candleTimeFrames=${timeFrames}&symbols=${symbolsList}&statuses=${String(
              statuses
            )}&page=0&size=10`
      );
      await updateUserOrders(
        orderBy(response, [(item) => new Date(item.createdAt)], ["desc"])
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const getOrders = useCallback(async () => {
    await fetchOrders();
  }, [isMountedRef, parameters]);
  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    if (layout.orderHistoryTab === 0) {
      if (userOrders.length > 0) {
      } else {
      }
    }
  }, [layout.orderHistoryTab, userOrders]);

  // const { enqueueSnackbar } = useSnackbar();

  // const handleDelete = async (orderId): Promise<void> => {
  //   try {
  //     await apiPost(`/orders/${orderId}/cancel`, {});
  //     enqueueSnackbar('Ordem excluída com sucesso!', {
  //       anchorOrigin: {
  //         horizontal: 'right',
  //         vertical: 'top',
  //       },
  //       variant: 'success',
  //     });

  //     getOrders();
  //   } catch (e) {
  //     enqueueSnackbar('A ordem não pode ser excluída', {
  //       anchorOrigin: {
  //         horizontal: 'right',
  //         vertical: 'top',
  //       },
  //       variant: 'error',
  //     });
  //   }
  // };

  const openModalItemDetail = (item) => {
    const _item = Object.entries(item).filter(
      (i) =>
        ![
          "asset",
          "accountid",
          "environment",
          "profit",
          "fees",
          "useremail",
          "id",
          "feerate",
          "createdat",
        ].includes(i[0].toLowerCase())
    );

    setItemDetail(_item);
    setOpenModalDetail(true);
  };

  const closeModalItemDetail = () => {
    setOpenModalDetail(false);
    setItemDetail([]);
  };

  return (
    <Box
      sx={{
        backgroundImage: "background.primary",
        fontSize: "80%",
        maxHeight: "225 !important",
      }}
    >
      <Scrollbar heigth={225}>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "225px !important" }}
        >
          <Table
            padding={isMobile ? "checkbox" : "normal"}
            size={`${isMobile ? "small" : null}`}
          >
            <TableHead>
              <TableRow>
                {columns.map((item, index) => {
                  if (
                    selectedTab === 1 &&
                    ["Action", "Accept"].includes(item)
                  ) {
                    return null;
                  }
                  if (selectedTab === 2 && ["Action"].includes(item)) {
                    return null;
                  }
                  if (selectedTab === 0 && ["Action"].includes(item)) {
                    return null;
                  }
                  return (
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "0.71875rem",
                        py: isMobile ? "8px !important" : 2,
                        width: "11.11%",
                      }}
                      key={item}
                    >
                      {t(item)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {userOrders.map((order) => (
                <TableRow
                  hover
                  key={order.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                    ".css-8boiwb-MuiTableCell-root.MuiTableCell-paddingCheckbox":
                      {
                        padding: isMobile && "0 0 0 10px !important",
                      },
                  }}
                  // onClick={() => openModalItemDetail(order)}
                >
                  {!isSimplified && (
                    <TableCell align="center" size="small">
                      <Typography color="textPrimary" variant="subtitle2">
                        {order.id}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="center" size="small">
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {order.symbol}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" size="small">
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {format(new Date(order.candleStartTime), "HH:mm")}
                    </Typography>
                  </TableCell>

                  <TableCell align="center" size="small">
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {order.candleTimeFrame}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" size="small">
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      sx={{
                        fontSize: 12,
                      }}
                    >
                      {order.direction ===
                      OrderDirectionEnum.BULL.toUpperCase() ? (
                        <Chip
                          size="small"
                          label={t("bull")}
                          sx={{
                            my: 0.5,
                            fontWeight: 500,
                            fontSize: 12,
                            color: "#00DB97",
                            backgroundColor: "#00DB4A08",
                          }}
                        />
                      ) : (
                        <Chip
                          size="small"
                          label={t("bear")}
                          sx={{
                            my: 0.5,
                            fontWeight: 500,
                            fontSize: 12,
                            color: "#FE025C",
                            backgroundColor: "#FE025C08",
                          }}
                        />
                      )}
                    </Typography>
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell align="center" size="small">
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"
                          sx={{
                            fontSize: 12,
                          }}
                        >
                          {numeral(order.invest).format("0,0.00")}
                        </Typography>
                      </TableCell>

                      {!isSimplified && (
                        <TableCell align="center" size="small">
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            sx={{
                              fontSize: 12,
                            }}
                          >
                            {numeral(order.fees).format("0,0.00")}
                          </Typography>
                        </TableCell>
                      )}

                      <TableCell align="center" size="small">
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"
                          sx={{
                            fontSize: 12,
                          }}
                        >
                          {numeral(order.refund).format("0,0.00")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"
                          sx={{
                            fontSize: 12,
                          }}
                        >
                          {numeral(order.accept).format("0,0.00")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Chip
                          label={t(translate(order.status.toString()))}
                          sx={{
                            ...labelsColors[order.status],
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell align="center" size="small">
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      sx={{
                        fontSize: 12,
                        color: labelsColors[order.status].color,
                      }}
                    >
                      {order.status.toLocaleLowerCase() === "win"
                        ? `+${numeral(order.profit).format("$0,0.00")}`
                        : order.status.toLocaleLowerCase() === "lose"
                        ? `-${numeral(order.accept).format("$0,0.00")}`
                        : numeral(order.accept).format("$0,0.00")}
                    </Typography>
                  </TableCell>
                  {/* {status === 'open' && (
                    <TableCell size="small">
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete && handleDelete(order.id)}
                      >
                        <TrashIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )} */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {loading && (
            <Box sx={{ textAlign: "center", p: 2 }}>
              <CircularProgress sx={{ width: 25, height: 25 }} size="small" />
            </Box>
          )}
        </TableContainer>
      </Scrollbar>
      <DetailItemHistory
        item={itemDetail}
        open={openModalDetail}
        columns={Columns}
        handleClose={closeModalItemDetail}
      />
    </Box>
  );
}
