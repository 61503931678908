import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

type AccountValidationType = {
  started?: boolean;
  finish?: boolean;
  activeForm?: string;
};
interface Layout {
  activeDrawer: string;
  activeTab: string;
  modal2fa: boolean;
  modalSupport: boolean;
  modalDeleteAccount: boolean;
  modalBetaDepositInfoModal: boolean;
  modalHowToDepositPix: boolean;
  modalAlertAccountReal: boolean;
  modalAlertAccountDemo: boolean;
  modalAlertWellcomeModal: boolean;
  modalRequireDocumentValidate: boolean;
  modalOutsideMarket: boolean;
  outsideMarketText: string;
  enableEditAccount: boolean;
  accountValidation: AccountValidationType;
  orderHistoryTab: number;
  relaodPage: boolean;
  showSensiveInfo: boolean;
  loadingTradingview: boolean;
}

export interface LayoutContextValue {
  layout: Layout;
  setActiveTab: (name: string) => void;
  setActiveDrawer: (name: string, tab?: string) => void;
  setModalTwoStepVerificationModal: (bool: boolean) => void;
  setModalDeleteAccountModal: (bool: boolean) => void;
  setModalHowToDepositPix: (bool: boolean) => void;
  setModalSupport: (bool: boolean) => void;
  setModalBetaDepositInfo: (bool: boolean) => void;
  setModalAlertAccountReal: (bool: boolean) => void;
  setModalAlertAccountDemo: (bool: boolean) => void;
  setModalAlertWellcomeModal: (bool: boolean) => void;
  setOrderHistoryTab: (id: number) => void;
  setModalRequireDocumentValidate: (bool: boolean) => void;
  setModalOutsideMarket: (
    bool: boolean,
    text: string,
    reload?: boolean
  ) => void;
  setAccountValidate: (data: AccountValidationType) => void;
  setEnableEditAccount: (bool: boolean) => void;
  setShowSensiveInfo: () => void;
  setLoadingTradingview: (bool: boolean) => void;
}

interface LayoutProviderProps {
  children?: ReactNode;
}

const initialLayout: Layout = {
  activeDrawer: "",
  activeTab: "login",
  modal2fa: false,
  modalSupport: false,
  modalDeleteAccount: false,
  modalBetaDepositInfoModal: false,
  modalHowToDepositPix: false,
  modalAlertAccountReal: false,
  modalAlertAccountDemo: false,
  modalAlertWellcomeModal: false,
  modalRequireDocumentValidate: false,
  modalOutsideMarket: false,
  outsideMarketText: "",
  enableEditAccount: false,
  accountValidation: {
    started: false,
    finish: false,
    activeForm: "documment",
  },
  orderHistoryTab: 0,
  relaodPage: false,
  showSensiveInfo:
    localStorage.getItem("showSensiveInfo") === "false" ? false : true,
  loadingTradingview: true,
};

const LayoutContext = createContext<LayoutContextValue>({
  layout: initialLayout,
  setActiveTab: (name: string) => {},
  setActiveDrawer: (name: string, tab?: string) => {},
  setModalTwoStepVerificationModal: (bool: boolean) => {},
  setModalDeleteAccountModal: (bool: boolean) => {},
  setModalHowToDepositPix: (bool: boolean) => {},
  setModalSupport: (bool: boolean) => {},
  setModalBetaDepositInfo: (bool: boolean) => {},
  setModalAlertAccountReal: (bool: boolean) => {},
  setModalAlertWellcomeModal: (bool: boolean) => {},
  setModalAlertAccountDemo: (bool: boolean) => {},
  setOrderHistoryTab: (id: number) => {},
  setModalRequireDocumentValidate: (bool: boolean) => {},
  setModalOutsideMarket: (bool: boolean, text: string, reload?: boolean) => {},
  setAccountValidate: (data: AccountValidationType) => {},
  setEnableEditAccount: (bool: boolean) => {},
  setShowSensiveInfo: () => {},
  setLoadingTradingview: (bool: boolean) => {},
});

export const LayoutProvider: FC<LayoutProviderProps> = (props) => {
  const { children } = props;
  const [layout, setLayout] = useState<Layout>(initialLayout);
  const [accountValidationStorege, setAccountValidationStorege] =
    useLocalStorage<AccountValidationType>("accountValidationStorege", {
      started: false,
      finish: false,
      activeForm: "documment",
    });

  const setActiveDrawer = (name: string, tab?: string) => {
    if (tab) {
      setLayout({ ...layout, activeDrawer: name, activeTab: tab });
    } else {
      setLayout({ ...layout, activeDrawer: name });
    }
  };

  const setShowSensiveInfo = () => {
    localStorage.setItem(
      "showSensiveInfo",
      (!layout.showSensiveInfo).toString()
    );
    setLayout({ ...layout, showSensiveInfo: !layout.showSensiveInfo });
  };

  const setActiveTab = (name: string) => {
    setLayout({ ...layout, activeTab: name });
  };

  const setOrderHistoryTab = (id: number) => {
    setLayout({ ...layout, orderHistoryTab: id });
  };

  const setModalTwoStepVerificationModal = (bool: boolean) => {
    setLayout({ ...layout, modal2fa: bool });
  };

  const setModalDeleteAccountModal = (bool: boolean) => {
    setLayout({ ...layout, modalDeleteAccount: bool });
  };

  const setModalHowToDepositPix = (bool: boolean) => {
    setLayout({ ...layout, modalHowToDepositPix: bool });
  };

  const setModalSupport = (bool: boolean) => {
    setLayout({ ...layout, modalSupport: bool });
  };

  const setModalBetaDepositInfo = (bool: boolean) => {
    setLayout({ ...layout, modalBetaDepositInfoModal: bool });
  };

  const setModalAlertAccountReal = (bool: boolean) => {
    setLayout({ ...layout, modalAlertAccountReal: bool });
  };

  const setModalAlertAccountDemo = (bool: boolean) => {
    setLayout({ ...layout, modalAlertAccountDemo: bool });
  };

  const setModalAlertWellcomeModal = (bool: boolean) => {
    setLayout({ ...layout, modalAlertWellcomeModal: bool });
  };

  const setModalRequireDocumentValidate = (bool: boolean) => {
    setLayout({ ...layout, modalRequireDocumentValidate: bool });
  };

  const setModalOutsideMarket = (
    bool: boolean,
    text: string,
    reload = true
  ) => {
    setLayout({
      ...layout,
      modalOutsideMarket: bool,
      outsideMarketText: text,
      relaodPage: reload,
    });
  };

  const setEnableEditAccount = (bool: boolean) => {
    const a = { ...layout, enableEditAccount: bool };
    console.log(a.enableEditAccount);
    setLayout(a);
  };

  const setAccountValidate = (data: AccountValidationType) => {
    const accountValidation = {
      ...layout.accountValidation,
      ...data,
    };
    setAccountValidationStorege(accountValidation);
    setLayout({
      ...layout,
      accountValidation,
    });
  };

  const setLoadingTradingview = (bool: boolean) => {
    setLayout({
      ...layout,
      loadingTradingview: bool,
    });
  };

  return (
    <LayoutContext.Provider
      value={{
        layout,
        setActiveTab,
        setActiveDrawer,
        setModalTwoStepVerificationModal,
        setModalDeleteAccountModal,
        setModalHowToDepositPix,
        setModalSupport,
        setModalBetaDepositInfo,
        setModalAlertAccountReal,
        setModalAlertAccountDemo,
        setModalAlertWellcomeModal,
        setModalRequireDocumentValidate,
        setModalOutsideMarket,
        setEnableEditAccount,
        setAccountValidate,
        setOrderHistoryTab,
        setShowSensiveInfo,
        setLoadingTradingview
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const LayoutConsumer = LayoutContext.Consumer;

export default LayoutContext;
